<template>
    <div v-loading="is_panding" class="computer-service-mashov">
        <div v-if="call_service_details" class="computer-service-mashov-wrapper">
            <div class="computer-service-mashov-wrapper-title">
                <h2> 
                    משוב לקריאה של סניף {{call_service_details.branche}}
                </h2>
            </div>
            <div class="computer-service-mashov-wrapper-content">
                <h3>סטטוס נוכחי: "{{call_service_details.status}}"</h3>
                <br>
                <h3 style="text-decoration: underline;">תיאור הקריאה:</h3>
                <p>{{call_service_details.description}}</p>
                <br>
                <h3 style="text-decoration: underline;"> בחירת שינוי סטטוס טיפול ל:</h3>
                <el-select
                    v-model="selected_status"
                    class="m-2"
                    placeholder="Select"
                    size="large"

                >
                    <el-option
                    v-for="status in statuses_options"
                        :key="status"
                        :value="status"
                        :disabled="status==call_service_details.status"
                    />
                </el-select>
                <el-button @click="handle_update_status" style="margin: 0 5px;" type="success">עדכון</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import {get_service_call_detail_from_db,update_status_service_call_in_db} from '../../scripts/scripts'
import {slide_pop_error,alert} from '../../../../../Methods/Msgs'

export default {
    props:['id'],
    setup (props) {
        

        const call_service_details = ref(null)

        const is_panding = ref(false)
        const update_panding = ref(false)
        const selected_status = ref('בטיפול')

        const statuses_options= ref([
            'בטיפול',
            'סגור'
        ])

        const handle_update_status = async() => {
            try{
                update_panding.value = true
                await update_status_service_call_in_db(props.id,selected_status.value)
                call_service_details.value.status = selected_status.value
                update_panding.value = false
                alert('success','הצלחה',`סטטוס שונה בהצלחה ל${selected_status.value}`)
            }catch(err){
                update_panding.value = false
                console.error(err.message);
                slide_pop_error(err.message)
            }
        }

        const init = async() => {
            try{
                is_panding.value = true
                call_service_details.value = await get_service_call_detail_from_db(props.id)
                if(call_service_details.value.status != 'פתוח'){
                    selected_status.value = call_service_details.value.status
                }
                console.log(call_service_details.value);
                is_panding.value = false
         
            }catch(err){
                is_panding.value = false
                console.error(err.message);
                alert('error',err.message)
            }
        }

        init()

        return {
            handle_update_status,
            call_service_details,
            is_panding,
            statuses_options,
            selected_status,
            update_panding,
        }
    }
}
</script>

<style scoped>
    .computer-service-mashov{
        width: 100%;
        height: 100%;
        padding: 0 5px;
        background: var(--main);
        overflow: hidden;
    }
    .computer-service-mashov-wrapper{
        width: 100%;
        max-width: 500px;
        background: #fff;
        border-radius: 10px;
        margin: 20px auto;
        color: #333;
    }
    .computer-service-mashov-wrapper-title{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid black;
    }
    .computer-service-mashov-wrapper-content{
        width: 100%;
        padding: 10px;
    }
</style>